*, ::after, ::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --primary-color: #234760;
  --secondary-color: #F4E04D;
  --tertiary-color: #234c60;
  --bar-width: 3rem;
  --bar-height: 0.25rem;
  --hamburger-gap: 0.5rem;
  --hamburger-height: calc(var(--bar-height) * 3 + var(--hamburger-gap) * 2);
  --x-width: calc(var(--hamburger-height) * 1.41421356237)
}
html {
  scroll-behavior: smooth;
}
body {
  /* padding-top: 4em; */
  overflow-x: hidden;
  background-color: var(--primary-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.blur {
  overflow-y: hidden;
  
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 7px;
  
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Background color of the track */
}

::-webkit-scrollbar-thumb {
  background:rgba(255, 255, 255, 0.4); /* Color of the thumb (the draggable part of the scrollbar) */
  border-radius: 50px;
}
