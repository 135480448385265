/* #hero {
    animation: fade-in 1s ease-in-out;
} */
.content {
    margin: 2rem;
    
}


.content button {
    padding: .75em 1.5em;
    border-radius: 5px;
    background-color: transparent;
    font-size: 1rem;
    border: solid var(--secondary-color) 1px;
    color: rgba(255, 255, 255, 0.8);
    animation: fade-up 1s ease-in-out;   
}


.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
#hello {
    color: var(--secondary-color);
    font-weight: normal;
    animation: fade-up 0.5s ease-in-out;
    
}
#name {
    color: rgba(255, 255, 255, 0.8);
    font-size: clamp(40px, 8vw, 80px);
    font-weight: normal;
    animation: fade-up 0.62s ease-in-out;
}
#name span {
    color: var(--secondary-color);
}
#design {
    color: rgba(255, 255, 255, 0.3);
    font-size: clamp(40px, 8vw, 80px);
    font-weight: normal;
    animation: fade-up 0.75s ease-in-out;
}
#design span {
    color: var(--secondary-color);
}
#developer {
    color: rgba(255, 255, 255, 0.8);
    font-weight: normal;
    margin: 1rem 0;
    font-size: clamp(16px, 2vw, 19px);
    animation: fade-up 0.9s ease-in-out;
    
}
#developer span {
    color: var(--secondary-color);
}
@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(30px);

      }
    100% {
        opacity: 1;
        transform: translateY(0);
        

    }

}


