.wrapper {
    margin-top: 2rem;
}
.container {
    position: relative;
    height: 25rem;
    z-index: 99;
    /* margin-bottom: 2rem; */
}
#projects {
    position: absolute;
    top: -110px;
    right: 0;
    color: rgba(244, 225, 77, 0.2);
    font-weight: normal;
    font-size: 23rem;
    
}
@media (min-width: 769px) and (max-width: 1279px) {
    .container {
        position: static;
        text-align: center;
        height: max-content;
    }
    #projects {
        position: static;
        font-size: clamp(60px, 10vh, 160px);
        color: rgba(244, 225, 77, 0.5);
    }
}
@media (max-width: 768px) {
    .container {
        position: static;
        text-align: center;
        height: max-content;
        margin-bottom: 2rem;
    }
    #projects {
        position: static;
        font-size: clamp(60px, 7vh, 160px);
        color: rgba(244, 225, 77, 0.5);
    }
}
