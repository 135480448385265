.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100vh;
}
#about {
    position: absolute;
    top: -110px;
    left: 0;
    color: rgba(244, 225, 77, 0.2);
    font-weight: normal;
    font-size: 23rem;
}
.mystory {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    z-index: 99;
    width: 55%;
    max-height: 100%;
    
}
.pic {
    display: flex;
    justify-content: center;
    height: 30rem;
    width: 22.5rem;
}
.pic img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 5px;
    border: solid var(--secondary-color) 1px;
}
.me {
    color:rgba(255, 255, 255, 0.6);
    font-size: 1.125em;
    padding: 1rem;
    
}
.me ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    list-style-type: square;
}
.skillwrapper {
    display: flex;
}
.first {
    margin-right: 1em;
}

@media (min-width: 769px) and (max-width: 1279px) {
    .heading {
        flex-direction: column;
        align-items: center;
    } 
    .mystory {
        width: 95%;
        max-height: 100%;
    }
    #about {
        position: static;
        font-size: clamp(60px, 10vh, 160px);
        color: rgba(244, 225, 77, 0.5);
        margin-bottom: 2rem;
    }
}
@media (max-width: 768px) {
    .heading {
        flex-direction: column;
        align-items: center;
    } 
    #about {
        position: static;
        font-size: clamp(60px, 7vh, 160px);
        color: rgba(244, 225, 77, 0.5);
    }
    .mystory {
        display: flex;
        flex-direction: column;
        align-items:center;
        width: 95%;
    }
    .pic {
        max-height: 100%;
        max-width: 100%;
       
    }
    .me {
        width: 100%;
    }
}


