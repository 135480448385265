/* #234c60*/
.test {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30rem;
    margin-bottom: 3em;
    /* background-color: aliceblue; */
}
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1.5rem;
    /* background-color: green; */
}
.screenshot {
    border-radius: 5px;
    height: 20rem;
    width: 32.5rem;
    position: relative;
    filter: drop-shadow(0px 0 6px rgb(10, 10, 10));
}
.screenshot img {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
}
.overlay {
    position: absolute;
    background-color: rgba(244, 225, 77, 0.3); 
    height: 100%;
    width: 100%;
    transition: all 200ms ease-in-out;
    border-radius: 5px;
}
.overlay:hover {
    opacity: 0;
}
.project_card {
    display: flex;
    flex-direction: column;
    width: 30rem;
    color:rgba(255, 255, 255, 0.6);
    /* background-color: aqua; */
    padding: 1rem 0;
}
.project_title {
    text-align: end;
}
.project_title h2 {
    margin: 0 0 1em 0;
    color:rgba(255, 255, 255, 0.7);
    font-weight: normal;
}
.project_number p {
    color: var(--secondary-color);
}
.project_description {
    background-color: var(--primary-color);
    border-radius: 5px;
    padding: 1rem;
    text-align: right;
    border: solid rgba(244, 225, 77, 0.4) 1px;
    filter: drop-shadow(1px 0 3px rgb(10, 10, 10));
    transition: all 200ms ease-in-out; 
}
.project_description:hover {
    filter: drop-shadow(1px 0 6px rgb(10, 10, 10));
    background-color: var(--tertiary-color);
    
}
.project_technologies {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.project_technologies li {
    margin: 1em 0 1em 1em;
}
.project_links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.project_links a {
    text-decoration: none;
    margin: 0 0 0 1em;
    color:rgba(255, 255, 255, 0.6);
    transition: all 400ms ease-in-out;
    background-color: rgba(244, 225, 77, 0.3);
    padding: 0.25rem;
    border-radius: 5px;
}
.project_links a:hover {
    color: var(--secondary-color);
    filter: drop-shadow(0px 0 6px rgb(10, 10, 10));
}
@media (max-width: 768px) {
    .wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        
    }
    .screenshot {
        position: absolute;
        height: 100%;
        width: 90%;
    }
    .overlay {
        background-color: rgba(35, 76, 96, 0.8);
    }
    .project_card {
        position: absolute;
        height: 100%;
        width: 90%;
        justify-content: center;
        align-items: center;
    }
    .project_title {
        text-align: center;
    }
    .project_description {
        background-color: transparent;
        border: none;
        text-align: center;
    }
    .project_description:hover {
        background-color: transparent;
    }
    .project_technologies {
        justify-content: center;
        width: 100%;
    }
    .project_technologies li {
        margin: 1rem 0.25rem;
    }
    .project_links {
        justify-content: center;
    }
}