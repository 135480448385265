.wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 300ms ease-in-out;
    background-color: var(--primary-color);
    /* background-color: rebeccapurple; */
    height: 4.75rem;
    width: 100%;
    animation: drop-down 1.5s ease-in-out;
}
@keyframes drop-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
    height: 72px;
    /* height: 320px; */
    color: rgba(255, 255, 255, 0.45);
    border: dotted var(--secondary-color) 1.75px;
    border-radius: 50%;
    width: 72px;
    margin-left: 1rem;
    /* width: 320px; */
    position: relative;
    transform-style: preserve-3d;
    animation: spin 6.5s linear infinite;
    transition: all 200ms ease-in-out;
}
.logo:hover {
    border: #d73b3e dotted 1.75px;
    
}
.logo::before,
.logo::after {
    border: dotted var(--secondary-color) 1.75px;
    border-radius: 50%;
    content: '';
    position: absolute;
    height: 72px;
    width: 72px;
    top: 0;
    left: 0;
    transition: all 200ms ease-in-out;
}
.logo::before {
    transform: rotateX(65deg);
}
.logo::after {
    transform: rotateX(-65deg);
}
.logo:hover::before,
.logo:hover::after {
    border: #d73b3e dotted 1.75px;
    
}
@keyframes spin {
    0% {
        transform: rotateX(0deg) rotateZ(0deg) rotateY(0deg);
      }
    100% {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg) ;
    }

}
.linkwrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background-color: aqua; */
    list-style: none;
    height: 100%;
    width: max-content;
    margin: 1rem 1rem 0 0;
}
.link {
    padding: 1em;
    border-bottom: solid transparent 2px;
    transition: all 200ms ease-in-out;
    position: relative;
    
}
#home::before {
    content: "01.";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.35em;
    font-size: 0.7rem;
    color: var(--secondary-color);
    transition: all 200ms ease-in-out;
    
}
#about::before {
    content: "02.";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25em;
    font-size: 0.7rem;
    color: var(--secondary-color);
    transition: all 200ms ease-in-out;
    
}
#work::before {
    content: "03.";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25em;
    font-size: 0.7rem;
    color: var(--secondary-color);
    transition: all 200ms ease-in-out;
    
}
#contact::before {
    content: "04.";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 1.25em;
    font-size: 0.7rem;
    color: var(--secondary-color);
    transition: all 200ms ease-in-out;
    
}
#home:hover::before,
#about:hover::before,
#work:hover::before,
#contact:hover::before {
    color: rgba(255, 255, 255, 0.6);
    
}
/* .link:hover {
    border-bottom: solid #F4E04D 2px;
    color: #F4E04D;
} */
.link a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    transition: all 200ms ease-in-out;
    font-size: 1.125rem;  
}
.link a:hover {
    color: #F4E04D;
}

.hamburgerwrapper {
    display: none;
    
}
@media (max-width: 768px) {
    .wrapper {
        width: 100%;
    }
    .linkwrapper {
        display: none;
    }
    .hamburgerwrapper {
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
    }
    .hamburger_menu {
        display: flex;
        flex-direction: column;
        gap: var(--hamburger-gap);
        position: absolute;
        right: 1.5rem;
        top: 1.25rem;
        z-index: 99;
    }
    .hamburger_menu::before,
    .hamburger_menu::after,
    .hamburger_menu input {
        content: "";
        width: var(--bar-width);
        height: var(--bar-height);
        background-color: var(--secondary-color);
        border-radius: 20px;
        transform-origin: left center;
        transition: opacity 300ms ease-in-out, 
        width 300ms ease-in-out, 
        rotate 300ms ease-in-out,
        translate 300ms ease-in-out;
    }
    .hamburger_menu input {
        appearance: none;
        outline: none;
        pointer-events: none;
        /* padding: 0;
        margin: 0; */
    }
    .hamburger_menu input:checked {
        opacity: 0;
        width: 0;
    }
    .hamburger_menu:has(input:checked)::before {
        rotate: 45deg;
        width: var(--x-width);
        translate: 0 calc(var(--bar-height) / -2);
        margin-left: 1em;
    }
    .hamburger_menu:has(input:checked)::after {
        rotate: -45deg;
        width: var(--x-width);
        translate: 0 calc(var(--bar-height) /2);
        margin-left: 1em;
        
    }
     .sidebar {
        display: flex;
        justify-content: center;
        transform: translate3d(200%, 0, 0);
        transition: all 400ms ease-in-out;
        background-color: var(--tertiary-color);
        padding-top: 5rem;
        padding-right: 1rem ;
        max-width: 100%;
        margin-left: 20%;
        text-align: end;
        height: 100vh;
        filter: drop-shadow(0 0 30px rgb(10, 10, 10));
        
    } 
    .hamburger_menu:has(input:checked) + .sidebar {
        transform: translate3d(0, 0, 0);
    }
    .sidebar nav {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 50%;
        font-weight: 600;
        width: 40%;
        /* background-color: aqua; */
        
    }
    .sidebar nav div {
        font-size: clamp(16px, 2vh, 25px);
        position: relative;
        padding: 1rem 0 0 1rem;
        width: 100%;
        text-align: center;
        /* background-color: black; */
    }
    .sidebar nav div a {
        color: rgba(255, 255, 255, 0.6);
        text-decoration: none;
    }
    #sbhome::before {
        content: "01.";
        display: inline-block;
        position: absolute;
        top: 1.35rem;
        left: 0;
        font-size: 0.7rem;
        color: var(--secondary-color);
        transition: all 200ms ease-in-out;
    }
    #sbabout::before {
        content: "02.";
        display: inline-block;
        position: absolute;
        top: 1.35rem;
        left: 0;
        font-size: 0.7rem;
        color: var(--secondary-color);
        transition: all 200ms ease-in-out;
    }
    #sbwork::before {
        content: "03.";
        display: inline-block;
        position: absolute;
        top: 1.35rem;
        left: 0;
        font-size: 0.7rem;
        color: var(--secondary-color);
        transition: all 200ms ease-in-out;
    }
    #sbcontact::before {
        content: "04.";
        display: inline-block;
        position: absolute;
        top: 1.35rem;
        left: 0;
        font-size: 0.7rem;
        color: var(--secondary-color);
        transition: all 200ms ease-in-out;
    }

}

/* Scroll */
.scrolldown {
    transform: translate3d(0, -100%, 0);
}
.scrollup {
    filter: drop-shadow(0 -10px 20px rgb(10, 10, 10));
}
.top {
    filter: none;
}
